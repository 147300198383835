import React from "react";

export const ApexIntro: React.FC = () => {
  return (
    <>
      <p className="h2 fs-1 text-black pb-4">Apex Portfolio Maker</p>

      <p className="display-6 fs-2 text-black">
        Welcome to Apex Pies! An app for people looking to invest in companies,
        but don’t know where to start.
      </p>
    </>
  );
};
